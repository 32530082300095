/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useEffect, useState, useContext } from 'react';
//import { GetCustomerinfo } from '../../Stub/Stub';
import {
  GetCustomer,
  GetCustomerId,
  getAgreementCustomerInfo,
  GetLegalHold,
  RunDE,
  getpaymentHistory
} from '../../../api/user';
//import CustomerInfoProps from './CustomerInfoInterface';
import clsx from 'clsx';
import { Link, useParams, useHistory } from 'react-router-dom';
import { AgreementContext } from '../../../context/AgreementContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Alertimage } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as ApprovedCustomer } from '../../../assets/images/DE-Approved.svg';
import { ReactComponent as DeclinedCustomer } from '../../../assets/images/DE-Declined.svg';
import { ReactComponent as VerificationRequired } from '../../../assets/images/DE-VerificationRequired.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close_icon_2.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  RACBadge,
  Grid,
  Typography,
  RACModalCard,
  RACButton,
  CircularProgress,
  MenuList,
  MenuItem,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { wizardStyles } from '../../../JSstyles/wizardStyles';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import { DE_APPROVAL_STATUS, DE_APPROVAL_STATUS_TEXT } from '../../../constants/constants';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
export default function CustomerInfo(props: any) {
  const history = useHistory();
  const { CoCustomerDetails, dereceiver, amountThrower } = props;
  const { featureFlagDetails } = useContext(FeatureFlagContext);

  const classes = agreementGlobalStyles();
  const wizStyle = wizardStyles();
  //PS_AD-1 A object declared to store the customer information from database
  const CustomerInfo = {
    CustomerName: '',
    CustomerID: '',
    PhoneNumber: '',
    DEstatus: '',
    ExpiryDate: '',
    CoCustomerName: null,
    CoCustomerId: null,
    Skip: '',
    Stolen: '',
    Hard: '',
  };
  interface Params {
    agreementId: string;
    customerId: string;
  }
  const {
    CustomerInfoContext,
    viewAgreementData,
    viewAgrData,
    setstoreExpiryDate,
    setentireGetCustomerData,
    approvalAmountHolder,
    setGlobalcusId,
    getCustomerResponse,
    setGetCustomerResponse
  } = useContext(AgreementContext);
  //PS_AD-1 A state variable declared to store the CustomerInfo object
  const { setcustomerInformation } = useContext(CustomerOrderAgreementContext)
  const [CustomerInformation, setCustomerInformation]: any =
    useState(CustomerInfo);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [CustomerValue, setCustomerValue] = CustomerInfoContext;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cocustomervalue, setcocustomervalue] = CustomerInfoContext;
  const { agreementId, customerId } = useParams<Params>();
  const [CoCustomerName, setCoCustomerName] = useState('');
  const [CoCustomerId, setCoCustomerId] = useState('');
  const [CustomerDEstaus, setCustomerDEstaus]: any = useState(null);
  const [CustomerExpDate, setCustomerExpDate]: any = useState(null);
  const [ApprovalAmount, setApprovalAmount]: any = useState(null);
  const [WeeklyApprovalAmount, setWeeklyApprovalAmount]: any = useState(null);
  const [successpopup, Setsuccesspopup] = useState(false);
  const [needDE, setneedDE] = useState(false);
  const [IscustomerapiFailed, SetIscustomerapiFailed] = useState(false);
  // const [entireGetCustomerData, setentireGetCustomerData] = useState<any>();
  const [hideShowProfile, sethideShowProfile] = useState('');
  const [isLegalHold, setIsLegalHold] = useState(false);

  const API_ERROR_MESSAGE = 'Unable to fetch the data';
  // const changeDEStatus = () => {
  //   console.log('Change De Statusfcsackjnsdkjv');

  // };
  const [showTooltip, setShowTooltip] = useState(false);
  const [showLevels, setShowLevels] = useState(false)

  const [totalRentalRevenueAmount, settotalRentalRevenueAmount] = useState('')
  useEffect(() => {

    const getpaymentHistoryRes = async () => {
      let getpaymentHistoryResponse = await getpaymentHistory(
        customerId,
        "agr", 0
      );
      settotalRentalRevenueAmount(getpaymentHistoryResponse?.data?.value?.customerInfo?.totalRentalRevenue)

    }
    getpaymentHistoryRes()
  }, [])
  useEffect(() => {
    console.log('ngbfvdssreaafawesdvcdsx', dereceiver);
    //console.log('receibed cdhvbhd data amount---->', amountThrower);
    console.log(
      'approvalAmountHolder cdhvbhd data amount------>',
      approvalAmountHolder
    );

    if (dereceiver === true && approvalAmountHolder !== undefined) {
      if (approvalAmountHolder.data.approvalResponse == '1') {
        setCustomerDEstaus('A');
      } else if (approvalAmountHolder.data.approvalResponse == '2') {
        setCustomerDEstaus('D');
      } else if (
        approvalAmountHolder.data.approvalResponse == '4' ||
        approvalAmountHolder.data.approvalResponse == '5'
      ) {
        setCustomerDEstaus('M');
      } else if (approvalAmountHolder.data.approvalResponse == '') {
        setCustomerDEstaus('');
      }
      console.log(
        'approvalAmountHolder.data.originalApprovalAmount',
        approvalAmountHolder.data.approvalAmount
      );
      console.log(
        'approvalAmountHolder.data.weeklyOriginalApprovalAmount',
        approvalAmountHolder.data.weeklyApprovalAmount
      );

      const RawExpDateArr =
        approvalAmountHolder.data.approvalExpirationDate !== ''
          ? approvalAmountHolder.data.approvalExpirationDate.split('-')
          : '';
      console.log('RawExpDateArr checker log', RawExpDateArr);
      setstoreExpiryDate(approvalAmountHolder.data.approvalExpirationDate);
      if (RawExpDateArr !== '') {
        const ExpDate =
          RawExpDateArr[1] + '/' + RawExpDateArr[2] + '/' + RawExpDateArr[0];
        console.log('ExpDate logger update log', ExpDate);

        setCustomerExpDate(ExpDate);
      } else {
        setCustomerExpDate('');
      }
      setApprovalAmount(
        Number(approvalAmountHolder.data.approvalAmount).toFixed(2)
      );
      setWeeklyApprovalAmount(
        Number(approvalAmountHolder.data.weeklyApprovalAmount).toFixed(2)
      );
    }
  }, [dereceiver]);

  useEffect(() => {
    if (
      CoCustomerDetails !== undefined &&
      CoCustomerDetails.coCustomerId !== undefined &&
      CoCustomerDetails.coCustomerId !== null &&
      CoCustomerDetails.coCustomerId !== '' &&
      CoCustomerDetails.coCustomerName !== undefined &&
      CoCustomerDetails.coCustomerName !== null &&
      CoCustomerDetails.coCustomerName !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log(
        'Inside Bind Name useeffect cocustomer details Name',
        CustomerInformation
      );
      console.log(
        'Inside Bind cocustomer context details Name',
        CustomerValue,
        CustomerInformation
      );
      setCustomerInformation({
        ...CustomerInformation,
        CoCustomerName: CoCustomerDetails.coCustomerName,
        CoCustomerId: CoCustomerDetails.coCustomerId,
      });
    }
  }, [CoCustomerDetails]);

  const RediectToCustomer = () => {
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  };
  const RediectToDashBoard = () => {
    history.push({
      pathname: `/dashboard/homepage`,
    });
  };

  /* eslint-disable sonarjs/cognitive-complexity */
  useEffect(() => {
    console.log('Customer id in customer info', customerId);
    let skip: '';
    let stolen: '';
    let hard: '';
    const getCustomer_APIcall = async () => {
      const currentStore = sessionStorage.getItem('storeNumber');
      const urlParams = new URLSearchParams(window.location.search);
      const coCustomerIdParams = urlParams.get('coCustomerId');
      // if (
      //   agreementId !== undefined &&
      //   agreementId !== null &&
      //   agreementId !== ''
      // ) {
      //   let response;
      //   console.log('raw viewAgrData log in custinfo', viewAgrData);

      //   //console.log('viewAgrData.data to check', viewAgrData.data);

      //   if (viewAgrData == undefined) {
      //     //.data removed
      //     console.log('viewAgreementData is  undefined', viewAgrData);

      //     response = await getAgreementCustomerInfo(agreementId);
      //     console.log('response to be checked', response);

      //     //response = response.data;
      //     console.log('response to be checked drilled', response);
      //     if (
      //       response !== undefined &&
      //       response.data !== undefined &&
      //       response.data.customer !== undefined &&
      //       response.data.customer.length > 0
      //     ) {
      //       response = response.data;
      //       const CustomerObj = {
      //         GlobalCustomerID: response.customer[0].gcdid,
      //         CustomerName: response.customer[0].customerName,
      //         CustomerId: response.customer[0].customerId,
      //         CusotmerPhoneNumber: response.customer[0].phoneNumber,
      //         CustomerEmail: response.customer[0].emailAddress,
      //         CoCustomerName: '',
      //         CoCustomerId: '', // Added
      //       };
      //       setCustomerInformation({
      //         ...CustomerInformation,
      //         CustomerName: response.customer[0].customerName,
      //         CustomerID: response.customer[0].customerId,
      //         PhoneNumber: response.customer[0].phoneNumber,
      //       });
      //       console.log('vdvf kjnjenv 644');
      //       setCustomerValue(CustomerObj);
      //       if (
      //         response !== undefined &&
      //         response.coCustomer !== undefined &&
      //         response.coCustomer.length > 0
      //       ) {
      //         setCustomerInformation({
      //           ...CustomerInformation,
      //           CustomerName: response.customer[0].customerName,
      //           CustomerID: response.customer[0].customerId,
      //           PhoneNumber: response.customer[0].phoneNumber,
      //           CoCustomerName: response.coCustomer[0].customerName,
      //           CoCustomerId: response.coCustomer[0].customerId,
      //         });
      //         console.log('Customer Object before', CustomerObj);
      //         const CustomerObject = {
      //           GlobalCustomerID: CustomerObj.GlobalCustomerID,
      //           CustomerName: CustomerObj.CustomerName,
      //           CustomerId: CustomerObj.CustomerId,
      //           CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
      //           // CustomerVehicles: CustomerObj.CustomerVehicles,
      //           CustomerEmail: CustomerObj.CustomerEmail,
      //           CoCustomerName: response.coCustomer[0].customerName,
      //           CoCustomerId: response.coCustomer[0].customerId,
      //         };
      //         console.log(
      //           'Customer Object after',
      //           CustomerObj,
      //           CustomerObject,
      //           CustomerValue,
      //           CustomerInfoContext
      //         );
      //         setCoCustomerName(response.coCustomer[0].customerName);
      //         console.log('cdv5v45vdf5v');

      //         setcocustomervalue(CoCustomerId);
      //         setCustomerValue(CustomerObject);
      //       }

      //       const getCustomerApprovalPayload = {
      //         firstName: response.customer[0].firstName,
      //         lastName: response.customer[0].lastName,
      //         ssn: response.customer[0].ssn,
      //         storeNumber: currentStore,
      //       };

      //       console.log(
      //         'Getcustomer Approval Object',
      //         getCustomerApprovalPayload
      //       );

      //       let customerDetails: any = await GetCustomer(
      //         customerId,
      //         getCustomerApprovalPayload
      //       );

      //       console.log(
      //         'Getcustomer Approval Response',
      //         getCustomerApprovalPayload,
      //         customerDetails
      //       );

      //       if (
      //         customerDetails !== undefined &&
      //         customerDetails.data !== undefined &&
      //         customerDetails.data.customerApprovalDetails !== undefined &&
      //         customerDetails.data.customerApprovalDetails !== null &&
      //         customerDetails.data.customerApprovalDetails !== ''
      //       ) {
      //         customerDetails = customerDetails.data;
      //         const DEstatus =
      //           customerDetails.customerApprovalDetails.approvalStatus;
      //         const RawExpDate =
      //           customerDetails.customerApprovalDetails.approvalExpirationDate;
      //         if (RawExpDate !== undefined) {
      //           const RawExpDateArr = RawExpDate.split('-');
      //           const ExpDate =
      //             RawExpDateArr[1] +
      //             '/' +
      //             RawExpDateArr[2] +
      //             '/' +
      //             RawExpDateArr[0];
      //           setCustomerExpDate(ExpDate);
      //         }

      //         const ApprovalAmt =
      //           customerDetails.customerApprovalDetails.approvalAmount;
      //         const WeeklyApprovalAmt =
      //           customerDetails.customerApprovalDetails.weeklyApprovalAmount;
      //         if (DEstatus !== undefined) {
      //           setCustomerDEstaus(DEstatus);
      //         }
      //         setApprovalAmount(ApprovalAmt.toFixed(2));
      //         setWeeklyApprovalAmount(WeeklyApprovalAmt.toFixed(2));
      //       }
      //     }
      //   } else {
      //     console.log('viewAgreementData is not undefined ', viewAgrData);

      //     response = viewAgrData.data;
      //     if (
      //       response !== undefined
      //       // response.customer !== undefined &&
      //       // response.customer.length > 0
      //     ) {
      //       //response = response.data;
      //       const CustomerObj = {
      //         GlobalCustomerID: response.agreementCustomer[0].globalCustomerId,
      //         CustomerName:
      //           response.agreementCustomer[0].firstName +
      //           ' ' +
      //           response.agreementCustomer[0].lastName,
      //         CustomerId: response.agreementCustomer[0].customerId,
      //         CusotmerPhoneNumber: response.agreementCustomer[0].phoneNumber,
      //         CustomerEmail: response.agreementCustomer[0].emailId,
      //         CoCustomerName: '',
      //         CoCustomerId: '', // Added
      //       };
      //       setCustomerInformation({
      //         ...CustomerInformation,
      //         CustomerName:
      //           response.agreementCustomer[0].firstName +
      //           ' ' +
      //           response.agreementCustomer[0].lastName,
      //         CustomerID: response.agreementCustomer[0].customerId,
      //         PhoneNumber: response.agreementCustomer[0].phoneNumber,
      //       });
      //       console.log('cdvjnk efw541ef');
      //       setCustomerValue(CustomerObj);
      //       if (
      //         response !== undefined &&
      //         response.agreementCustomer.length > 1
      //       ) {
      //         setCustomerInformation({
      //           ...CustomerInformation,
      //           CustomerName:
      //             response.agreementCustomer[0].firstName +
      //             ' ' +
      //             response.agreementCustomer[0].lastName,
      //           CustomerID: response.agreementCustomer[0].customerId,
      //           PhoneNumber: response.agreementCustomer[1].phoneNumber,
      //           CoCustomerName:
      //             response.agreementCustomer[1].firstName +
      //             ' ' +
      //             response.agreementCustomer[1].lastName,
      //           CoCustomerId: response.agreementCustomer[1].customerId,
      //         });
      //         console.log('Customer Object before', CustomerObj);
      //         const CustomerObject = {
      //           GlobalCustomerID: CustomerObj.GlobalCustomerID,
      //           CustomerName: CustomerObj.CustomerName,
      //           CustomerId: CustomerObj.CustomerId,
      //           CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
      //           // CustomerVehicles: CustomerObj.CustomerVehicles,
      //           CustomerEmail: CustomerObj.CustomerEmail,
      //           CoCustomerName:
      //             response.agreementCustomer[1].firstName +
      //             ' ' +
      //             response.agreementCustomer[1].lastName,
      //           CoCustomerId: response.agreementCustomer[1].customerId,
      //         };
      //         console.log(
      //           'Customer Object after',
      //           CustomerObj,
      //           CustomerObject,
      //           CustomerValue,
      //           CustomerInfoContext
      //         );
      //         setCoCustomerName(
      //           response.agreementCustomer[1].firstName +
      //             ' ' +
      //             response.agreementCustomer[1].lastName
      //         );
      //         console.log('vfvf evjnejr 46848');

      //         setcocustomervalue(CoCustomerId);
      //         setCustomerValue(CustomerObject);
      //       }

      //       const getCustomerApprovalPayload = {
      //         firstName: response.agreementCustomer[0].firstName,
      //         lastName: response.agreementCustomer[0].lastName,
      //         ssn: response.agreementCustomer[0].ssn,
      //         storeNumber: currentStore,
      //       };

      //       console.log(
      //         'Getcustomer Approval Object',
      //         getCustomerApprovalPayload
      //       );

      //       let customerDetails: any = await GetCustomer(
      //         customerId,
      //         getCustomerApprovalPayload
      //       );

      //       console.log(
      //         'Getcustomer Approval Response',
      //         getCustomerApprovalPayload,
      //         customerDetails
      //       );

      //       if (
      //         customerDetails !== undefined &&
      //         customerDetails.data !== undefined &&
      //         customerDetails.data.customerApprovalDetails !== undefined &&
      //         customerDetails.data.customerApprovalDetails !== null &&
      //         customerDetails.data.customerApprovalDetails !== ''
      //       ) {
      //         customerDetails = customerDetails.data;
      //         const DEstatus =
      //           customerDetails.customerApprovalDetails.approvalStatus;
      //         const RawExpDate =
      //           customerDetails.customerApprovalDetails.approvalExpirationDate;
      //         if (RawExpDate !== undefined) {
      //           const RawExpDateArr = RawExpDate.split('-');
      //           const ExpDate =
      //             RawExpDateArr[1] +
      //             '/' +
      //             RawExpDateArr[2] +
      //             '/' +
      //             RawExpDateArr[0];
      //           setCustomerExpDate(ExpDate);
      //         }

      //         const ApprovalAmt =
      //           customerDetails.customerApprovalDetails.approvalAmount;
      //         const WeeklyApprovalAmt =
      //           customerDetails.customerApprovalDetails.weeklyApprovalAmount;
      //         if (DEstatus !== undefined) {
      //           setCustomerDEstaus(DEstatus);
      //         }
      //         setApprovalAmount(ApprovalAmt.toFixed(2));
      //         setWeeklyApprovalAmount(WeeklyApprovalAmt.toFixed(2));
      //       }
      //     }
      //   }
      //   // eslint-disable-next-line no-console
      //   console.log('getagreement customer response', response);
      //   // if (
      //   //   response !== undefined &&
      //   //   response.data !== undefined &&
      //   //   response.data.customer !== undefined &&
      //   //   response.data.customer.length > 0
      //   // ) {
      //   //   response = response.data;
      //   //   const CustomerObj = {
      //   //     GlobalCustomerID: response.customer[0].gcdid,
      //   //     CustomerName: response.customer[0].customerName,
      //   //     CustomerId: response.customer[0].customerId,
      //   //     CusotmerPhoneNumber: response.customer[0].phoneNumber,
      //   //     CustomerEmail: response.customer[0].emailAddress,
      //   //   };
      //   //   setCustomerInformation({
      //   //     ...CustomerInformation,
      //   //     CustomerName: response.customer[0].customerName,
      //   //     CustomerID: response.customer[0].customerId,
      //   //     PhoneNumber: response.customer[0].phoneNumber,
      //   //   });
      //   //   setCustomerValue(CustomerObj);
      //   //   if (
      //   //     response !== undefined &&
      //   //     response.coCustomer !== undefined &&
      //   //     response.coCustomer.length > 0
      //   //   ) {
      //   //     setCustomerInformation({
      //   //       ...CustomerInformation,
      //   //       CustomerName: response.customer[0].customerName,
      //   //       CustomerID: response.customer[0].customerId,
      //   //       PhoneNumber: response.customer[0].phoneNumber,
      //   //       CoCustomerName: response.coCustomer[0].customerName,
      //   //       CoCustomerId: response.coCustomer[0].customerId,
      //   //     });
      //   //     console.log('Customer Object before', CustomerObj);
      //   //     const CustomerObject = {
      //   //       GlobalCustomerID: CustomerObj.GlobalCustomerID,
      //   //       CustomerName: CustomerObj.CustomerName,
      //   //       CustomerId: CustomerObj.CustomerId,
      //   //       CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
      //   //       // CustomerVehicles: CustomerObj.CustomerVehicles,
      //   //       CustomerEmail: CustomerObj.CustomerEmail,
      //   //       CoCustomerName: response.coCustomer[0].customerName,
      //   //       CoCustomerId: response.coCustomer[0].customerId,
      //   //     };
      //   //     console.log(
      //   //       'Customer Object after',
      //   //       CustomerObj,
      //   //       CustomerObject,
      //   //       CustomerValue,
      //   //       CustomerInfoContext
      //   //     );
      //   //     setCoCustomerName(response.coCustomer[0].customerName);
      //   //     setcocustomervalue(CoCustomerId);
      //   //     setCustomerValue(CustomerObject);
      //   //   }

      //   //   const getCustomerApprovalPayload = {
      //   //     firstName: response.customer[0].firstName,
      //   //     lastName: response.customer[0].lastName,
      //   //     ssn: response.customer[0].ssn,
      //   //     storeNumber: currentStore,
      //   //   };

      //   //   console.log(
      //   //     'Getcustomer Approval Object',
      //   //     getCustomerApprovalPayload
      //   //   );

      //   //   let customerDetails: any = await GetCustomer(
      //   //     customerId,
      //   //     getCustomerApprovalPayload
      //   //   );

      //   //   console.log(
      //   //     'Getcustomer Approval Response',
      //   //     getCustomerApprovalPayload,
      //   //     customerDetails
      //   //   );

      //   //   if (
      //   //     customerDetails !== undefined &&
      //   //     customerDetails.data !== undefined &&
      //   //     customerDetails.data.customerApprovalDetails !== undefined &&
      //   //     customerDetails.data.customerApprovalDetails !== null &&
      //   //     customerDetails.data.customerApprovalDetails !== ''
      //   //   ) {
      //   //     customerDetails = customerDetails.data;
      //   //     const DEstatus =
      //   //       customerDetails.customerApprovalDetails.approvalStatus;
      //   //     const RawExpDate =
      //   //       customerDetails.customerApprovalDetails.approvalExpirationDate;
      //   //     if (RawExpDate !== undefined) {
      //   //       const RawExpDateArr = RawExpDate.split('-');
      //   //       const ExpDate =
      //   //         RawExpDateArr[1] +
      //   //         '/' +
      //   //         RawExpDateArr[2] +
      //   //         '/' +
      //   //         RawExpDateArr[0];
      //   //       setCustomerExpDate(ExpDate);
      //   //     }

      //   //     const ApprovalAmt =
      //   //       customerDetails.customerApprovalDetails.approvalAmount;
      //   //     const WeeklyApprovalAmt =
      //   //       customerDetails.customerApprovalDetails.weeklyApprovalAmount;
      //   //     if (DEstatus !== undefined) {
      //   //       setCustomerDEstaus(DEstatus);
      //   //     }
      //   //     setApprovalAmount(ApprovalAmt.toFixed(2));
      //   //     setWeeklyApprovalAmount(WeeklyApprovalAmt.toFixed(2));
      //   //   }
      //   // }
      //   //need to add api call for get customer
      // } else {
      let legalHoldResponse: any = {
        legalHoldRes: {},
      };
      let response: any = {
        getCustomerRes: {},
      };

      console.log('legal hold response before', legalHoldResponse, response);

      await Promise.all([
        GetLegalHold(customerId),
        GetCustomer(customerId, currentStore),
      ]).then((resp) => {
        [legalHoldResponse, response] = resp;
      });
      // debugger;
      console.log('legal hold response', legalHoldResponse, response);
      if (
        legalHoldResponse !== undefined &&
        legalHoldResponse !== '' &&
        legalHoldResponse !== null &&
        legalHoldResponse.legalHoldStatusRes !== undefined &&
        legalHoldResponse.legalHoldStatusRes.length > 0 &&
        legalHoldResponse.legalHoldStatusRes[0].legalHoldStatus !== undefined &&
        legalHoldResponse.legalHoldStatusRes[0].legalHoldStatus === true
      ) {
        Setsuccesspopup(true);
        setIsLegalHold(true);
      } else {
        if (response.status === 200) {
          setcustomerInformation(response.data.GetCustomer.value)
          setGlobalcusId(response.data.GetCustomer.value.globalCustomerId);
          setGetCustomerResponse(response?.data)
          console.log('dvdv', response.data.GetCustomer.value.globalCustomerId);
          if (
            response.data.GetApproval.value.customerApprovalDetails !==
            undefined
          ) {
            console.log('reached here idhvnhsdf check svc');
            console.log(
              'testing the requid date',
              response.data.GetApproval.value.customerApprovalDetails
                .approvalExpirationDate
            );

            setstoreExpiryDate(
              response.data.GetApproval.value.customerApprovalDetails
                .approvalExpirationDate
            );
          } else if (response.data.GetApproval.value == '') {
            console.log('as the 204 reesponse');
            setCustomerExpDate('');
            setstoreExpiryDate(undefined);
            setWeeklyApprovalAmount('');
            setApprovalAmount('');
            setCustomerDEstaus('');
          } else if (
            response.data.GetApproval.value.status == 500 ||
            response.data.GetApproval.value.code == 400 ||
            response.data.GetApproval.value.code == 500 ||
            response.data.GetApproval.value.status == 400
          ) {
            console.log('reached here blocskdvjds check svc');
            setstoreExpiryDate(undefined);
            setCustomerExpDate('');
            setWeeklyApprovalAmount('');
            setApprovalAmount('');
            setCustomerDEstaus('');
          }

          setentireGetCustomerData(response.data.GetCustomer.value);
          if (response.data.GetCustomer.value.coCustomer === 'N') {
            setGetCustomerResponse(response?.data)

            const customerDetails = response.data;
            console.log('GetCustomer Lambda response', customerDetails);
            if (customerDetails !== undefined) {
              const GlobalCustomerId =
                customerDetails.GetCustomer.value.globalCustomerId;
              const CusName = `${customerDetails.GetCustomer.value.firstName} ${customerDetails.GetCustomer.value.lastName}`;
              const CusId = customerDetails.GetCustomer.value.customerId;
              const CusPhoneNumArr = customerDetails.GetCustomer.value.phones;
              let CusPhoneNum = '';
              if (CusPhoneNumArr !== undefined && CusPhoneNumArr.length > 0) {
                const IndexofCell = CusPhoneNumArr.findIndex(
                  (obj) => obj.phoneType === 'CELL' && obj.primary === 'Y'
                );
                if (IndexofCell >= 0) {
                  CusPhoneNum =
                    customerDetails.GetCustomer.value.phones[IndexofCell]
                      .phoneNumber;
                }
              }

              const CustVehicles = customerDetails.GetCustomer.value.vehicles;
              const CusEmail = customerDetails.GetCustomer.value.emailAddress;
              const CoCustId =
                customerDetails.GetCustomer.value.coCustomerId !== null
                  ? customerDetails.GetCustomer.value.coCustomerId
                  : '';
              if (
                (response.data.GetCustomer.value.skip !== undefined &&
                  response.data.GetCustomer.value.skip == 'Y') ||
                (response.data.GetCustomer.value.stolen !== undefined &&
                  response.data.GetCustomer.value.stolen == 'Y') ||
                (response.data.GetCustomer.value.hard !== undefined &&
                  response.data.GetCustomer.value.hard == 'Y')
              ) {
                skip = response.data.GetCustomer.value.skip;
                stolen = response.data.GetCustomer.value.stolen;
                hard = response.data.GetCustomer.value.stolen;
              }
              if (
                customerDetails.GetApproval !== undefined &&
                customerDetails.GetApproval.value !== '' &&
                customerDetails.GetApproval.value !== undefined &&
                customerDetails.GetApproval.value.customerApprovalDetails !==
                undefined &&
                customerDetails.GetApproval.value.code !== 400 &&
                customerDetails.GetApproval.value.code !== 500 &&
                customerDetails.GetApproval.value.code !== 401
              ) {
                const DEstatus =
                  customerDetails.GetApproval.value.customerApprovalDetails
                    .approvalStatus;
                const RawExpDate =
                  customerDetails.GetApproval.value.customerApprovalDetails
                    .approvalExpirationDate;
                if (RawExpDate !== undefined) {
                  const RawExpDateArr =
                    RawExpDate !== '' ? RawExpDate.split('-') : '';
                  const ExpDate =
                    RawExpDateArr[1] +
                    '/' +
                    RawExpDateArr[2] +
                    '/' +
                    RawExpDateArr[0];
                  setCustomerExpDate(ExpDate);
                } else {
                  setCustomerExpDate('');
                }

                const ApprovalAmt =
                  customerDetails.GetApproval.value.customerApprovalDetails
                    .approvalAmount !== undefined &&
                    customerDetails.GetApproval.value.customerApprovalDetails
                      .approvalAmount !== null
                    ? customerDetails.GetApproval.value.customerApprovalDetails
                      .approvalAmount
                    : '';
                const WeeklyApprovalAmt =
                  customerDetails.GetApproval.value.customerApprovalDetails
                    .weeklyApprovalAmount !== undefined &&
                    customerDetails.GetApproval.value.customerApprovalDetails
                      .weeklyApprovalAmount !== null
                    ? customerDetails.GetApproval.value.customerApprovalDetails
                      .weeklyApprovalAmount
                    : '';
                if (DEstatus !== undefined) {
                  setCustomerDEstaus(DEstatus);
                } else {
                  setCustomerDEstaus('');
                }

                setApprovalAmount(
                  ApprovalAmt !== '' ? '1234' : ''
                );
                setWeeklyApprovalAmount(
                  WeeklyApprovalAmt !== '' ? '150' : ''
                );

              }
              setCustomerInformation({
                ...CustomerInformation,
                CustomerName: CusName,
                CustomerID: CusId,
                PhoneNumber: CusPhoneNum,
                CoCustomerName: '',
                CoCustomerId: '',
              });
              console.log('CustomerInfo value inside weekly approaval amount');
              const CustomerObj = {
                GlobalCustomerID: GlobalCustomerId,
                CustomerName: CusName,
                CustomerId: CusId,
                CusotmerPhoneNumber: CusPhoneNum,
                CustomerVehicles: CustVehicles,
                CustomerEmail: CusEmail,
                CoCustomerName:
                  CustomerValue !== undefined &&
                    CustomerValue.CoCustomerName !== undefined
                    ? CustomerValue.CoCustomerName
                    : '',
                CoCustomerId: CoCustId,
                Skip: skip,
                Stolen: stolen,
                Hard: hard,
              };

              console.log(
                'customer obj with cocusyotmer Name and CocustomerId and skip stolen',
                CustomerObj
              );
              console.log('few5f4654v65d4v');

              setCustomerValue(CustomerObj);

              // if (
              //   agreementId !== undefined &&
              //   agreementId !== null &&
              //   agreementId !== ''
              // ) {
              //   console.log('add cocustomer blocker due to agreement ID');
              // } else {
              if (
                customerDetails.GetCustomer.value.coCustomerId !== undefined &&
                customerDetails.GetCustomer.value.coCustomerId !== null
              ) {
                const GetcoworkerResponse = await GetCustomerId(
                  customerDetails.GetCustomer.value.coCustomerId
                );

                console.log(
                  'passing customerid to customerdetail',
                  GetcoworkerResponse
                );
                if (
                  (response.data.GetCustomer.value.skip !== undefined &&
                    response.data.GetCustomer.value.skip == 'Y') ||
                  (response.data.GetCustomer.value.stolen !== undefined &&
                    response.data.GetCustomer.value.stolen == 'Y') ||
                  (response.data.GetCustomer.value.hard !== undefined &&
                    response.data.GetCustomer.value.hard == 'Y')
                ) {
                  skip = response.data.GetCustomer.value.skip;
                  stolen = response.data.GetCustomer.value.stolen;
                  hard = response.data.GetCustomer.value.stolen;
                }

                if (
                  GetcoworkerResponse !== undefined &&
                  GetcoworkerResponse.data !== undefined &&
                  GetcoworkerResponse.data !== null &&
                  GetcoworkerResponse !== null
                ) {
                  setCoCustomerId(
                    GetcoworkerResponse.data.customerId !== undefined &&
                      GetcoworkerResponse.data.customerId !== null
                      ? GetcoworkerResponse.data.customerId
                      : ''
                  );
                  const coCustomerFirstName =
                    GetcoworkerResponse.data.firstName !== undefined &&
                      GetcoworkerResponse.data.firstName !== null
                      ? GetcoworkerResponse.data.firstName
                      : '';
                  const coCustomerLastName =
                    GetcoworkerResponse.data.lastName !== undefined &&
                      GetcoworkerResponse.data.lastName !== null
                      ? GetcoworkerResponse.data.lastName
                      : '';

                  setCustomerInformation({
                    ...CustomerInformation,
                    CustomerName: CusName,
                    CustomerID: CusId,
                    PhoneNumber: CusPhoneNum,
                    CoCustomerName:
                      coCustomerFirstName + ' ' + coCustomerLastName,
                    CoCustomerId:
                      customerDetails.GetCustomer.value.coCustomerId,
                  });
                  console.log('Customer Object before', CustomerObj);
                  const CustomerObject = {
                    GlobalCustomerID: GlobalCustomerId,
                    CustomerName: CustomerObj.CustomerName,
                    CustomerId: CustomerObj.CustomerId,
                    CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
                    CustomerVehicles: CustomerObj.CustomerVehicles,
                    CustomerEmail: CustomerObj.CustomerEmail,
                    CoCustomerName:
                      coCustomerFirstName + ' ' + coCustomerLastName,
                    CoCustomerId:
                      customerDetails.GetCustomer.value.coCustomerId,
                    Skip: skip,
                    Stolen: stolen,
                    Hard: hard,
                  };
                  console.log(
                    'Customer Object after',
                    CustomerObj,
                    CustomerObject,
                    CustomerValue,
                    CustomerInfoContext
                  );
                  setCoCustomerName(
                    coCustomerFirstName + ' ' + coCustomerLastName
                  );
                  console.log('edv51v5fdv141');

                  setcocustomervalue(CoCustomerId);

                  setCustomerValue(CustomerObject);
                }

                // from the context adding the cocustomer name and cocustomerid in the wizard.
              } else if (
                coCustomerIdParams !== undefined &&
                coCustomerIdParams !== null &&
                coCustomerIdParams !== '' &&
                coCustomerIdParams !== 'N'
              ) {
                const GetcoworkerResponse = await GetCustomerId(
                  coCustomerIdParams
                );

                console.log(
                  'passing customerid to customerdetail',
                  GetcoworkerResponse
                );
                if (
                  (response.data.GetCustomer.value.skip !== undefined &&
                    response.data.GetCustomer.value.skip == 'Y') ||
                  (response.data.GetCustomer.value.stolen !== undefined &&
                    response.data.GetCustomer.value.stolen == 'Y') ||
                  (response.data.GetCustomer.value.hard !== undefined &&
                    response.data.GetCustomer.value.hard == 'Y')
                ) {
                  skip = response.data.GetCustomer.value.skip;
                  stolen = response.data.GetCustomer.value.stolen;
                  hard = response.data.GetCustomer.value.stolen;
                }

                if (
                  GetcoworkerResponse !== undefined &&
                  GetcoworkerResponse.data !== undefined &&
                  GetcoworkerResponse.data !== null &&
                  GetcoworkerResponse !== null
                ) {
                  setCoCustomerId(
                    GetcoworkerResponse.data.customerId !== undefined &&
                      GetcoworkerResponse.data.customerId !== null
                      ? GetcoworkerResponse.data.customerId
                      : ''
                  );
                  const coCustomerFirstName =
                    GetcoworkerResponse.data.firstName !== undefined &&
                      GetcoworkerResponse.data.firstName !== null
                      ? GetcoworkerResponse.data.firstName
                      : '';
                  const coCustomerLastName =
                    GetcoworkerResponse.data.lastName !== undefined &&
                      GetcoworkerResponse.data.lastName !== null
                      ? GetcoworkerResponse.data.lastName
                      : '';
                  const CustomerObjects = {
                    GlobalCustomerID: GlobalCustomerId,
                    CustomerName: CustomerObj.CustomerName,
                    CustomerId: CustomerObj.CustomerId,
                    CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
                    CustomerVehicles: CustomerObj.CustomerVehicles,
                    CustomerEmail: CustomerObj.CustomerVehicles,
                    CoCustomerName:
                      coCustomerFirstName + ' ' + coCustomerLastName,
                    CoCustomerId: coCustomerIdParams,
                    Skip: skip,
                    Stolen: stolen,
                    Hard: hard,
                  };

                  setCustomerInformation({
                    ...CustomerInformation,
                    CustomerName: CusName,
                    CustomerID: CusId,
                    PhoneNumber: CusPhoneNum,
                    CoCustomerName:
                      coCustomerFirstName + ' ' + coCustomerLastName,
                    CoCustomerId: coCustomerIdParams,
                  });
                  setCoCustomerName(
                    coCustomerFirstName + ' ' + coCustomerLastName
                  );
                  console.log('gfgb 65f65b frb');

                  setcocustomervalue(coCustomerIdParams);
                  console.log(
                    'Customer Object',
                    CustomerObj,
                    CustomerObjects,
                    CustomerValue,
                    CustomerInfoContext
                  );
                  console.log('dv1vf1rf65v651f');

                  setCustomerValue(CustomerObjects);
                }

                // from the context adding the cocustomer name and cocustomerid in the wizard.
              }
              // }
            } else {
              Setsuccesspopup(true);
            }
          } else {
            SetIscustomerapiFailed(true);
            Setsuccesspopup(true);
          }

          // setstoreExpiryDate(
          //   response.data.GetApproval.value.customerApprovalDetails
          //     .approvalExpirationDate
          // );
          console.log(
            'view response for get approval----->',
            response.data.GetApproval
          );
          // if (
          //   response.data.GetApproval.value.customerApprovalDetails !==
          //   undefined
          // ) {
          //   console.log('reached here idhvnhsdf check svc');
          //   console.log(
          //     'testing the requid date',
          //     response.data.GetApproval.value.customerApprovalDetails
          //       .approvalExpirationDate
          //   );

          //   setstoreExpiryDate(
          //     response.data.GetApproval.value.customerApprovalDetails
          //       .approvalExpirationDate
          //   );
          // } else if (response.data.GetApproval.value.status == 500) {
          //   console.log('reached here blocskdvjds check svc');
          //   setstoreExpiryDate(undefined);
          // }
          //setstoreExpiryDate('2021-02-15');
          console.log('before setting the entireGetCustomerData', response);

          setentireGetCustomerData(response.data.GetCustomer.value);
          // const currentData = new Date();
          // const mydate = new Date(
          //   response.data.GetApproval.value.customerApprovalDetails.approvalExpirationDate
          // );
          // console.log(
          //   'response.data.GetApproval.value.customerApprovalDetails.approvalExpirationDate',
          //   response.data.GetApproval.value.customerApprovalDetails
          //     .approvalExpirationDate
          // );
        }
      }
      // }
      // console.log('customerInforesponse', response);
    };
    getCustomer_APIcall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tempMethod = () => {
    console.log('Empty Console');
  };

  const errorpopup = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Alertimage></Alertimage>
          <Typography className={classes.my2}>
            {isLegalHold === true
              ? 'Unable to create agreement due to customer status'
              : IscustomerapiFailed === false
                ? 'This customer is already a cocustomer on another agreement.'
                : 'This Customer is a co-customer'}
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          {isLegalHold === true ? (
            <RACButton
              variant="contained"
              color="primary"
              isRounded={false}
              onClick={() => RediectToDashBoard()}
            >
              Ok
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              isRounded={false}
              onClick={() => RediectToCustomer()}
            >
              Ok
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };
  const hideShowPopup = () => {
    hideShowProfile == '' ? sethideShowProfile('show') : sethideShowProfile('');
  };

  const hideShowDiv = () => {
    sethideShowProfile('');
  };
  const customerInfoLoading = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Fetching Customer's Information
          </Typography>
          <Typography
            className={`${classes.greyFont} ${classes.font12} ${classes.mt2}`}
          >
            Please wait
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {CustomerInformation.CustomerName !== '' &&
        CustomerInformation.CustomerID !== '' ? (
        <Grid className={`${classes.floatRight} ${wizStyle.custompadding}`}>
          <div className={`${classes.floatLeft} ${classes.me3} ${classes.mt1}`}>
            <span className={classes.agrInfoPanel}>
              {totalRentalRevenueAmount && featureFlagDetails.CustomerRewards == '1' && Number(totalRentalRevenueAmount) >= Number(featureFlagDetails.RewardsEligibilityAmount) ?
                <>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <span
                      onMouseOver={() => {
                        setShowTooltip(true);
                      }}
                      onMouseOut={() => {
                        setShowTooltip(false);
                      }}
                      style={{
                        backgroundColor: '#198754',
                        color: 'white',
                        padding: '3px 7px',
                        borderRadius: '11px',
                        fontSize: '12px',
                        cursor: 'pointer'
                      }}
                      onClick={() => { history.push(`/customer/update/${customerId}/customer`) }}
                    >
                      {CustomerInformation.CustomerName}
                    </span>
                    {showTooltip && (
                      <div style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        marginBottom: '5px',
                        backgroundColor: '#e9f1f6',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '10px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        zIndex: 2000
                      }}>
                        Eligible for RAC Select Rewards
                      </div>
                    )}
                  </div>
                </> : <Link
                  className={classes.racpadLinkCustomer}
                  to={`/customer/update/${customerId}/customer`}
                >
                  {CustomerInformation.CustomerName}
                </Link>}



            </span>
            <Typography variant="caption" className={classes.agrInfoPanel}>
              {CustomerInformation.CustomerID}
            </Typography>
          </div>
          {/* <div
            className={`${classes.floatLeft} ${classes.me3} ${classes.textRight} ${classes.cursorPointer} ${classes.mt1}`}
          >
            <span className={`${classes.agrInfoPanel}`}>
              {CustomerDEstaus == null ? (
                <Skeleton
                  count={1}
                  baseColor="#FFFFFF"
                  highlightColor="#e7e7e7"
                />
              ) : null}
              {CustomerDEstaus === 'A' ? (
                <ApprovedCustomer
                  className={classes.wd70}
                  onClick={hideShowPopup}
                />
              ) : null}
              {CustomerDEstaus === 'D' ||
              CustomerDEstaus === '' ||
              CustomerDEstaus === 'E' ? (
                <DeclinedCustomer
                  className={classes.wd70}
                  onClick={hideShowPopup}
                />
              ) : null}
              {CustomerDEstaus === 'M' ? (
                <VerificationRequired
                  className={classes.wd70}
                  onClick={hideShowPopup}
                />
              ) : null}
            </span>
          </div> */}
          <RACButton
            className={`${classes.me1} ${classes.fontstyle} ${classes.font12} ${classes.bold}`}
            onClick={hideShowPopup}
            startIcon={
              CustomerDEstaus === 'A' ? (
                <ApprovedCustomer
                  // className={classes.wd70}
                  onClick={hideShowPopup}
                />
              ) : CustomerDEstaus === 'D' ||
                CustomerDEstaus === '' ||
                CustomerDEstaus === 'E' ? (
                <DeclinedCustomer
                  // className={classes.wd70}
                  onClick={hideShowPopup}
                />
              ) : CustomerDEstaus === 'M' ? (
                <VerificationRequired
                  // className={classes.wd70}
                  onClick={hideShowPopup}
                />
              ) : null
            }
          >
            More Info
          </RACButton>
          <OutsideClickHandler onOutsideClick={hideShowDiv}>
            <MenuList
              data-testid="logouttest"
              className={clsx({
                [wizStyle.racGlobalSearchShow]: hideShowProfile === 'show',
              })}
              classes={{
                root: clsx(
                  wizStyle.racProfileContent,
                  wizStyle.racProfileMenu,
                  wizStyle.menuList
                ),
              }}
              aria-labelledby="profilemenu"
            >
              <MenuItem
                className={wizStyle.menuItem}
                disableRipple
                disableTouchRipple
              >
                <Grid item md={12}>
                  <CloseIcon
                    onClick={() => sethideShowProfile('')}
                    className={wizStyle.closeIcon}
                  />
                </Grid>
              </MenuItem>
              <MenuItem
                className={wizStyle.menuItem}
                disableRipple
                disableTouchRipple
              >
                <Grid container>
                  <Grid item md={6}>
                    <Typography className={wizStyle.captionFont}>
                      Co-Customer:
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    {CustomerInformation.CoCustomerName == null ? (
                      <Skeleton
                        count={1}
                        baseColor="#FFFFFF"
                        highlightColor="#e7e7e7"
                      />
                    ) : (
                      <Typography className={classes.fontBold}>
                        {CustomerInformation.CoCustomerName == ''
                          ? '-'
                          : CustomerInformation.CoCustomerName}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem className={wizStyle.menuItem}>
                <Grid container>
                  <Grid item md={6}>
                    <Typography className={wizStyle.captionFont}>
                      ID:
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    {CustomerInformation.CoCustomerId == null ? (
                      <Skeleton
                        count={1}
                        baseColor="#FFFFFF"
                        highlightColor="#e7e7e7"
                      />
                    ) : (
                      <Typography>
                        {CustomerInformation.CoCustomerId == ''
                          ? '-'
                          : CustomerInformation.CoCustomerId}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem className={wizStyle.menuItem}>
                <Grid container>
                  <Grid item md={6}>
                    <Typography className={wizStyle.captionFont}>
                      Approval Amount:{' '}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    {ApprovalAmount == null ? (
                      <Skeleton
                        count={1}
                        baseColor="#FFFFFF"
                        highlightColor="#e7e7e7"
                      />
                    ) : (
                      <Typography>
                        {ApprovalAmount !== ''
                          ? '$' + ApprovalAmount
                          : '$ 0.00'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem className={wizStyle.menuItem}>
                <Grid container>
                  <Grid item md={6}>
                    <Typography className={wizStyle.whiteSpace}>
                      Weekly Approval Amount:{' '}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    {WeeklyApprovalAmount == null ? (
                      <Skeleton
                        count={1}
                        baseColor="#FFFFFF"
                        highlightColor="#e7e7e7"
                      />
                    ) : (
                      <Typography>
                        {WeeklyApprovalAmount !== ''
                          ? '$' + WeeklyApprovalAmount
                          : '$ 0.00'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </MenuItem>
              {/* Verification Optimization start */}

              {featureFlagDetails?.CustomerVerificationOptimization == 1 ? <>

                {getCustomerResponse && getCustomerResponse?.GetCustomer?.value && getCustomerResponse?.GetApproval?.value?.customerApprovalDetails && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '-1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '0' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == null) ? <>
                  <span
                    style={{
                      backgroundColor: '#bb37ae',
                      color: 'white',
                      borderRadius: '11px',
                      marginRight: '13px',
                      float: 'left',
                      padding: '5px 10px',
                      fontSize: '0.75em',
                      fontWeight: 700,
                      lineHeight: 1,
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      verticalAlign: 'baseline',
                    }}
                  >
                    {getCustomerResponse?.GetCustomer?.value?.salesLeadId && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '-1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == null) ? 'Previous-Web' : getCustomerResponse?.GetCustomer?.value?.salesLeadId && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '0' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '1') ? 'New-Web' : (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '-1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == null) ? 'Previous' : 'New'}
                  </span>
                </> : null}
                {/* Customer type binding end */}

                {/* Verification Level badge start*/}
                {getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus == 'COMPLETED') ? <span
                  style={{
                    backgroundColor: '#0d6efd',
                    color: 'white',
                    borderRadius: '11px',
                    marginRight: '13px',
                    float: 'left',
                    padding: '5px 10px',
                    fontSize: '0.75em',
                    fontWeight: 700,
                    lineHeight: 1,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'baseline',
                  }}
                >
                  {'Verified'}
                </span> : getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus == 'PENDING' ?
                  <span style={{ position: 'relative' }}>
                    <span
                      onMouseOver={() => {
                        setShowLevels(true);
                      }}
                      onMouseOut={() => {
                        setShowLevels(false);
                      }}
                      style={{
                        backgroundColor: '#e85c5c',
                        color: 'white',
                        borderRadius: '11px',
                        marginRight: '13px',
                        float: 'left',
                        padding: '5px 10px',
                        fontSize: '0.75em',
                        fontWeight: 700,
                        lineHeight: 1,
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        verticalAlign: 'baseline',
                        cursor: 'pointer',
                      }}
                    >
                      {getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel && getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus != 'COMPLETED' ? `Level - ${getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel.replace('-1', '3')} ` : 'Verified'}
                    </span>
                    {showLevels && (
                      <div style={{
                        position: 'absolute',
                        bottom: '0px',
                        right: 0,
                        transform: 'translateX(-50%)',
                        marginBottom: '5px',
                        backgroundColor: '#e9f1f6',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '10px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        zIndex: 2000,
                        fontWeight: 'bold'
                      }}>
                        {getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel == 1 ? 'Identity and Residence Verification Required' : getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel == 2 ? 'Identity, Residence, and Employment Verification Required' : (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel == '3' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel == '-1') ? 'Identity, Residence, Employment, and Reference Verification Required' : ''}
                      </div>
                    )}
                  </span> : null}

                {/* Verification Level badge end */}

              </> : null}

              {/* Verification Optimization end */}
              <MenuItem className={wizStyle.menuItem}>
                <Grid container>
                  <Grid item md={6}>
                    <Typography className={wizStyle.captionFont}>
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    {/* <Typography></Typography> */}
                    {CustomerDEstaus === DE_APPROVAL_STATUS.APPROVED ? (
                      <RACBadge
                        backgroundColor="#5AE286"
                        color="#fff"
                        OnclickNeed={false}
                        TextValue={DE_APPROVAL_STATUS_TEXT.APPROVED}
                        handleOnClick={tempMethod}
                      />
                    ) : null}
                    {CustomerDEstaus === DE_APPROVAL_STATUS.DECLINED ? (
                      <RACBadge
                        backgroundColor="#FF0000"
                        color="#fff"
                        OnclickNeed={false}
                        TextValue={DE_APPROVAL_STATUS_TEXT.DECLINED}
                        handleOnClick={tempMethod}
                      />
                    ) : null}
                    {CustomerDEstaus === DE_APPROVAL_STATUS.CONDITIONAL_APPROVAL ? (
                      <RACBadge
                        backgroundColor="#FFA500"
                        color="#fff"
                        OnclickNeed={false}
                        TextValue={DE_APPROVAL_STATUS_TEXT.CONDITIONAL_APPROVAL}
                        handleOnClick={tempMethod}
                      />
                    ) : null}
                    {CustomerDEstaus === '' ? (
                      <RACBadge
                        backgroundColor="#FF0000"
                        color="#fff"
                        OnclickNeed={false}
                        TextValue={DE_APPROVAL_STATUS_TEXT.NO_DE}
                        handleOnClick={tempMethod}
                      />
                    ) : null}
                    {CustomerDEstaus === DE_APPROVAL_STATUS.EXPIRED ? (
                      <RACBadge
                        backgroundColor="#FF0000"
                        color="#fff"
                        OnclickNeed={false}
                        TextValue={DE_APPROVAL_STATUS_TEXT.EXPIRED}
                        handleOnClick={tempMethod}
                      />
                    ) : null}
                    {CustomerDEstaus == null ? (
                      <Skeleton
                        count={1}
                        baseColor="#FFFFFF"
                        highlightColor="#e7e7e7"
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem className={wizStyle.menuItem}>
                <Grid container>
                  <Grid item md={6}>
                    <Typography className={wizStyle.captionFont}>
                      Expires on:
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    {/* {CustomerExpDate !== '' && CustomerDEstaus !== 'D' ? (
                  <Typography>{CustomerExpDate}</Typography>
                ) : (
                  <Skeleton
                    count={1}
                    baseColor="#FFFFFF"
                    highlightColor="#e7e7e7"
                  />
                )} */}
                    {CustomerExpDate == null ? (
                      <Skeleton
                        count={1}
                        baseColor="#FFFFFF"
                        highlightColor="#e7e7e7"
                      />
                    ) : (
                      <Typography>
                        {CustomerExpDate !== '' && CustomerDEstaus !== 'D'
                          ? CustomerExpDate
                          : '-'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </MenuItem>
            </MenuList>
          </OutsideClickHandler>

          {/* <RACModalCard
      isOpen={needDE}
      maxWidth="xs"
      // eslint-disable-next-line react/no-children-prop
      children={runDEModal()}
    /> */}
        </Grid>
      ) : (
        <Grid
          className={`${classes.floatRight} ${wizStyle.custompadding}  ${classes.width20} ${classes.me3} ${classes.mt1}`}
          data-testid="CustomerInfo"
        >
          <Skeleton count={2} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
          <RACModalCard
            isOpen={true}
            maxWidth="xs"
            borderRadius={'25px !important'}
            // eslint-disable-next-line react/no-children-prop
            children={customerInfoLoading()}
          />
        </Grid>
      )}
      <RACModalCard
        isOpen={successpopup}
        maxWidth="xs"
        // title="Modal title"
        onClose={() => RediectToCustomer()}
        // eslint-disable-next-line react/no-children-prop
        children={errorpopup()}
        borderRadius="20px"
      />
    </>
  );
}
